<template>
 <div class="login-box">
  <!-- /.login-logo -->
  <div class="card card-outline card-primary">
    <div class="card-header text-center">
      <a class="h1"><b>MSPC</b> Online</a>
    </div>
    <div class="card-body">
      <p class="login-box-msg">Sign in to start your session</p>
        <div class="input-group mb-3">
          <input v-model="this.txtEmail" type="email" class="form-control" placeholder="Email">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div class="input-group mb-3">
          <input  v-model="this.txtPass" type="password" class="form-control" placeholder="Password">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- /.col -->
          <div class="col-12">
            <button type="submit" @click="ShowToast" class="btn btn-primary btn-block">Sign In</button>
          </div>
          <!-- /.col -->
        </div>  
      <p class="mb-1 mt-1 float-right">
        <a href="/forgotpassword">I forgot my password</a>
      </p>
    </div>
    <!-- /.card-body -->
  </div>
  <!-- /.card -->
</div>
</template>

<script>

import axios from 'axios';
document.title = "MSPC Online - Security Login";
export default {
  
  name: 'LoginView',
   data: () => ({
    error : [],
    txtEmail : '',
    txtPass: '',
    toast: false
  }),
  mounted() {
    
  },
  methods: {
    async ShowToast(){
       
    },
    async LoginPost() {
      try {
        this.loginerrcnt = 0
        if (this.Loginemailaddress == "") {
          this.text = "Email address is required"
          this.failedsnackbar = true;
          this.loginerrcnt = this.loginerrcnt + 1;
          return false;
        }
        if (this.Loginpassword == "") {
          this.text = "Password address is required"
          this.failedsnackbar = true;
          this.loginerrcnt = this.loginerrcnt + 1;
          return false;
        }
        
        const logincred = {
          Emailaddress : this.Loginemailaddress,
          Password : this.Loginpassword
        };
        const response = await axios.post(`https://localhost:44384/api/v1/users/user-authenticate`, JSON.stringify(logincred),{
          headers: {
            "Content-Type": "application/json",
          }
        })                
        if (response.data.msg === "LGN_SUC"){          
            this.snackbar = true;
            this.text = "Login Success";
            this.$refs.clearemail.reset()
            this.$refs.clearpassword.reset()
            localStorage.setItem("jwt",response.data.token);
            localStorage.setItem("type",response.data.roleid);
            localStorage.setItem("user",response.data.userid);
            if (response.data.roleid == 1){
              this.$router.push('/admin/dashboard');
            } else {
              this.$router.push('/client/home');
            }
        } else {            
            this.$refs.clearpassword.reset()
            this.text = "User Not Found"
            this.failedsnackbar = true;            
            return false;
        }
      } catch (e) {
        this.errors.push(e)
      }
    },
  }
}
</script>
