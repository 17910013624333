<template>
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item" v-for="link in accessList" :key="link.title">
            <a v-bind:href="link.link"  v-bind:class="link.class">
                <p>
                  {{link.title}}   
                  <i v-if="link.treeview" class="right fas fa-angle-left"></i>         
                </p>
            </a>
            <ul class="nav nav-treeview" v-if="link.treeview">              
              <li class="nav-item" v-for="item in link.treeviewitems" :key="item.title">
                <a :href="item.link" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{item.title}}</p>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
</template>
<script>
export default {  
  name: 'SidebarComponent',
  data: ()=> ({
    accessList : [
        {link: '/dashboard', title: 'Dashboard', icon: 'nav-icon fas fa-tachometer-alt', class: 'nav-link', treeview:false},
        {link: '/mkkstocks', title: 'MKK', icon: 'nav-icon fas fa-book', class: 'nav-link', treeview: true, 
          treeviewitems :[
            {link: '/mkkstocks', title: 'Stocks', icon: 'nav-icon fas fa-tachometer-alt', class: 'nav-link'},
            {link: '/mmkktransmital', title: 'Transmittal', icon: 'nav-icon fas fa-tachometer-alt', class: 'nav-link'}
            ]
        },
        {link: '/usermaintenance', title: 'User Maintenance', icon: 'nav-icon fas fa-users', class: 'nav-link', treeview:false}
    ]
  }),
}
</script>

