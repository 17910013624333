<template>
<div class="col-12">
    <div class="card card-primary card-outline card-tabs">
            <div class="card-header p-0 pt-1 border-bottom-0">
            <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                <li class="nav-item">
                <a class="nav-link active" id="custom-tabs-three-coils-tab" data-toggle="pill" href="#custom-tabs-three-coils" role="tab" aria-controls="custom-tabs-three-coils" aria-selected="true">COILS</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" id="custom-tabs-three-slitted-tab" data-toggle="pill" href="#custom-tabs-three-slitted" role="tab" aria-controls="custom-tabs-three-slitted" aria-selected="false">SLITTED COILS</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" id="custom-tabs-three-finished-tab" data-toggle="pill" href="#custom-tabs-three-finished" role="tab" aria-controls="custom-tabs-three-finished" aria-selected="false">FINISHED GOODS</a>
                </li>
            </ul>
            </div>
            <div class="card-body">
            <div class="tab-content" id="custom-tabs-three-tabContent">
                <div class="tab-pane fade show active" id="custom-tabs-three-coils" role="tabpanel" aria-labelledby="custom-tabs-three-coils-tab">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">&nbsp;</h3>
                    </div>
                    <!-- /.card-header -->
                        <MkkCoils />
                    <!-- /.card-body -->
                    </div>
                </div>
                <div class="tab-pane fade" id="custom-tabs-three-slitted" role="tabpanel" aria-labelledby="custom-tabs-three-slitted-tab">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">&nbsp;</h3>
                    </div>
                    <!-- /.card-header -->
                        <MkkSlitted />
                    <!-- /.card-body -->
                    </div>
                </div>
                <div class="tab-pane fade" id="custom-tabs-three-finished" role="tabpanel" aria-labelledby="custom-tabs-three-finished-tab">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">&nbsp;</h3>
                    </div>
                    <!-- /.card-header -->
                        <MkkFinished />
                    <!-- /.card-body -->
                    </div>
                </div>
            </div>
            </div>
            <!-- /.card -->
        </div>
</div>
</template>
<script>
document.title = "MSPC Online - MKK Division";
import MkkCoils from '../../components/MKK/mkkstockscoils.vue'
import MkkSlitted from '../../components/MKK/mkkstockscoils.vue'
import MkkFinished from '../../components/MKK/mkkstocksfinished.vue'
export default {
    name: 'MkkStocks',
    components: {
        MkkCoils,
        MkkSlitted,
        MkkFinished
    }
};
</script>

<style lang="scss"></style>