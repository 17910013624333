<template>
<div v-if="this.$route.name == 'login'" class="hold-transition login-page">
    <LoginView />
</div>
<div v-if="this.$route.name == 'forgotpassword'" class="hold-transition login-page">
    <ForgotPasswordView />
</div>
 <div class="wrapper" v-if="this.$route.name != 'login' && this.$route.name != 'forgotpassword'">
<!-- Navbar -->
  <Navbar />
  <!-- /.navbar -->
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
      <img src="assets/dist/img/favicon.ico" alt="Mayer's Logo" class="brand-image img-circle elevation-3"
           style="opacity: .8">
      <span class="brand-text font-weight-light">MSPC Online</span>
    </a>
    <!-- Sidebar -->
    <Sidebar />
    <!-- /.sidebar -->
  </aside>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">{{ this.pageName }}</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">MSPC Online</a></li>
              <li class="breadcrumb-item active">{{ this.pageName }}</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <!-- Main content -->
    <section class="content">
      <router-view/>
    </section>   
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
  <footer class="main-footer">
    <strong>Copyright &copy; {{ new Date().getFullYear() }}  <a href="#">Mayer Steel Pipe Corporation</a>.</strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 1.0.0
    </div>
  </footer>
  
</div>
<!-- ./wrapper -->
</template>

<script>
import LoginView from './views/LoginView.vue'
import ForgotPasswordView from './views/ForgotPasswordView.vue'
import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar.vue'
export default {
  components:{
    LoginView,
    ForgotPasswordView,
    Navbar,
    Sidebar
  },
  data: ()=> ({
    pageName: ''
  }),
  updated() {
    if (this.$route.name === 'usermaintenance'){
      this.pageName = 'User Maintenance'
    } else if (this.$route.name === 'dashboard'){
      this.pageName = 'Dashboard'
    } else if (this.$route.name === 'mkkstocks'){
      this.pageName = 'MKK Stocks'
    } else {
      this.pageName = this.$route.name
    }
  }
}
</script>
