<template>
<div class="col-12">
  <div class="card card-primary">
  <div class="card-header">
    <h3 class="card-title">Overall Reports</h3>

    <div class="card-tools">
      <button type="button" class="btn btn-tool" data-card-widget="collapse">
        <i class="fas fa-minus"></i>
      </button>
      <button type="button" class="btn btn-tool" data-card-widget="remove">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
      <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
  <!-- /.card-body -->
</div>
</div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

document.title = "MSPC Online - Dashboard";
export default {
  name: 'HomeView',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartData: {
        labels: [ 'Stocks', 'Transmittals', 'Sales', 'Warehouse' ],
        datasets: [ 
          { label: 'MKK', data: [40, 60, 24, 50], backgroundColor: '#001eff' },
          { label: 'Spiral', data: [30, 48, 20, 40], backgroundColor: '#001be7' },
          { label: 'ERW', data: [35, 40, 17, 45], backgroundColor: '#0119cb' },
          { label: 'Bagbaguin', data: [40, 45, 16, 30], backgroundColor: '#021496' },
          { label: 'Continental', data: [30, 40, 15, 35], backgroundColor: '#000b5e' },
         ]
      },
      chartOptions: {
        responsive: true
      }
    }
  }
}
</script>
<style>
canvas {
  max-height: 600px !important; 
  max-width: 100% !important;
}
</style>
