<template>
    <div class="col-12">
                    <div class="card card-primary card-outline card-tabs">
              <div class="card-header p-0 pt-1 border-bottom-0">
                <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true">User List</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false">User Access</a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="custom-tabs-three-tabContent">
                  <div class="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">&nbsp;</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">              
                            <ag-grid-vue
                            class="ag-theme-alpine"
                            style="height: 500px"
                            :columnDefs="columnDefs.value"
                            :rowData="rowData.value"
                            :defaultColDef="defaultColDef"    
                            :paginationAutoPageSize="true"
                            :pagination="true"    
                            animateRows="true"
                            @cell-clicked="cellWasClicked"
                            @grid-ready="onGridReady"
                            >
                            </ag-grid-vue>
                    </div>
                    <!-- /.card-body -->
                    </div>
                  </div>
                  <div class="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                     Mauris tincidunt mi at erat gravida, eget tristique urna bibendum. Mauris pharetra purus ut ligula tempor, et vulputate metus facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Maecenas sollicitudin, nisi a luctus interdum, nisl ligula placerat mi, quis posuere purus ligula eu lectus. Donec nunc tellus, elementum sit amet ultricies at, posuere nec nunc. Nunc euismod pellentesque diam.
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue3";  // the AG Grid Vue Component
import { reactive, onMounted, ref } from "vue";

import "ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/dist/styles/ag-theme-alpine.css"; // Optional theme CSS

export default {
 name: "UserMaintenance",
 components: {
   AgGridVue,
 },
 setup() {
   const gridApi = ref(null); // Optional - for accessing Grid's API

   // Obtain API from grid's onGridReady event
   const onGridReady = (params) => {
     gridApi.value = params.api;
   };

   const rowData = reactive({}); // Set rowData to Array of Objects, one Object per Row

   // Each Column Definition results in one Column.
   const columnDefs = reactive({
     value: [{ field: "make" }, { field: "model" }, { field: "price" }],
   });

   // DefaultColDef sets props common to all Columns
   const defaultColDef = {
     sortable: true,
     filter: true,
     resizable: true,
     flex: 1,
   };

   // Example load data from sever
   onMounted(() => {
     fetch("https://www.ag-grid.com/example-assets/row-data.json")
       .then((result) => result.json())
       .then((remoteRowData) => (rowData.value = remoteRowData));
   });

   return {
     onGridReady,
     columnDefs,
     rowData,
     defaultColDef,
     cellWasClicked: (event) => { // Example of consuming Grid Event
       console.log("cell was clicked", event);
     },
   };
 },
};
</script>

<style lang="scss"></style>