<template>
        <div class="login-box">
        <div class="card card-outline card-primary">
            <div class="card-header text-center">
            <a href="/" class="h1"><b>MSPC</b> Online</a>
            </div>
            <div class="card-body">
            <p class="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>
                <div class="input-group mb-3">
                <input type="email" class="form-control" placeholder="Email">
                <div class="input-group-append">
                    <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                    </div>
                </div>
                </div>
                <div class="row">
                <div class="col-12">
                    <button type="submit" class="btn btn-primary btn-block">Request new password</button>
                </div>
                <!-- /.col -->
                </div>
            <p class="mt-3 mb-1">
                <a href="/">Back to Login</a>
            </p>
            </div>
            <!-- /.login-card-body -->
        </div>
        </div>
</template>
<script>

document.title = "MSPC Online - Reset Password";
export default {  
  name: 'ForgotPasswordView',
}
</script>
